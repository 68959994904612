import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { SeoContent } from '~/types/models'

export const useSeoContentStore = defineStore('seo-content', () => {
  const { $api } = useNuxtApp()

  const list = ref<SeoContent[]>([])

  async function getList(url?: string, model_id?: string) {
    return $api.seoContents
      .getSeoContents(url, model_id)
      .then((data) => {
        list.value = data
      })
      .catch(() => {
        list.value = []
      })
  }

  return { list, getList }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSeoContentStore, import.meta.hot))
}